function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        const context = this;
        clearTimeout(timeoutId); // Clear the previous timer
        timeoutId = setTimeout(() => {
            func.apply(context, args); // Call the function with the correct context and arguments
        }, delay);
    };
}


//Header functionality for new design
/*=============== SHOW MENU ===============*/
const showMenu = (toggleId2, navId) => {
    var ForStickyHeight = $('.nav__data').outerHeight();

    const toggle = document.getElementById(toggleId2),
        nav = document.getElementById(navId);
    toggle.addEventListener('click', () => {
        // Add show-menu class to nav menu
        nav.classList.toggle('show-menu')
        // Add show-icon to show and hide menu icon
        toggle.classList.toggle('show-icon');
        $('body').toggleClass('open-menu');

        var Navheight = $('.mobile_Header_top').outerHeight() + $('.stickyDiv ').outerHeight();

        // Check if mobile_Header_top is displayed for checking it is not sticky header
        if ($('.menuScrollMargin').length === 0) {
            var currentMenuHeight = window.innerHeight;
            currentMenuHeight = currentMenuHeight - Navheight;

        } else {
            var currentMenuHeight = window.innerHeight;
            currentMenuHeight = currentMenuHeight - ForStickyHeight
        }
        $('.show-menu .nav__list').css('height', currentMenuHeight + 2 + 'px');
        var fixedBottomHeight = $('.select_interest_bottomFixed').outerHeight();
        var totalNavlistheight = $('.nav__list').outerHeight();
        var heightInnerScroll = totalNavlistheight - fixedBottomHeight;
        $('.dropdown__container').css('height', heightInnerScroll + 'px')
    })

}
//skip to main content function
document.addEventListener("DOMContentLoaded", function () {
    const skipLink = document.getElementById("skipToMain");
    const mainContent = document.getElementById("main_content");
    skipLink.addEventListener("focus", function () {
        document.body.classList.add("skip-visible");
    });
    skipLink.addEventListener("click", function (event) {
        event.preventDefault();
        const firstInteractiveElement = mainContent.querySelector("a, button");
        if (firstInteractiveElement) {
            firstInteractiveElement.focus();
        }
    });
    skipLink.addEventListener("blur", function () {
        document.body.classList.remove("skip-visible");
    });
});
document.addEventListener("DOMContentLoaded", function () {
    const buttons = document.querySelectorAll('.discover-buttons a');
    buttons.forEach(button => {
        const span = button.querySelector('span');
        if (span) {
            const spanWidth = span.offsetWidth;
            button.style.width = (spanWidth + 70) + 'px'; // Add some padding to account for the arrow and padding
        }
    });
});
$(document).on('click', function (event) {
    // Check if the clicked element is within '.container.dropdown__container' or '.country-selector'
    if (!$(event.target).closest('.container.dropdown__container, .country-selector').length) {
        // Find '.dropdown__item' elements that have the 'show-dropdown' class and contain '.country-selector' as a child
        $('.dropdown__item.show-dropdown:has(.country-selector)').removeClass('show-dropdown');
    }
});
showMenu('nav-toggle', 'nav-menu')
/*=============== SHOW DROPDOWN MENU ===============*/
const dropdownItems = document.querySelectorAll('.dropdown__item')
// 1. Select each dropdown item
dropdownItems.forEach((item) => {

    const dropdownButton = item.querySelector('.dropdown__button')
    // 2. Select each button click
    dropdownButton.addEventListener('click', () => {
        checkDropdownHeight(dropdownButton);


        // 7. Select the current show-dropdown class
        const showDropdown = document.querySelector('.show-dropdown')
        $('.active').removeClass('active');

        // 5. Call the toggleItem function
        toggleItem(item);

        // 8. Remove the show-dropdown class from other items
        if (showDropdown && showDropdown !== item) {
            toggleItem(showDropdown);
        }
    })
})

$('.megaMenu_title').on('click', function () {
    var $dropdownSubContainer = $(this).closest('.dropdown__container').find('.dropdownSub__container');
    setTimeout(function () {
        // Check if the 'transparent_scrollbar' class is present
        if (!$dropdownSubContainer.hasClass('transparent_scrollbar') && !$dropdownSubContainer.hasClass('show-scrollbar')) {
            // Only add 'show-scrollbar' class if 'transparent_scrollbar' is not present
            $dropdownSubContainer.addClass('show-scrollbar');
        } else {
            $dropdownSubContainer.removeClass('show-scrollbar');
        }
    }, 500);
});

//megamenu second level 
$('.megaMenu_title').on('click keypress', function (event) {
    $('.transparent_scrollbar').removeClass('transparent_scrollbar');
    // Handle click and keyboard "Enter" keypress events
    if (event.type === "click" || (event.type === "keypress" && event.key === "Enter")) {
        $('.content').removeClass('nohide');
        $(this).closest('.megaMenuDropdown').find('.content').addClass('nohide').slideToggle();
        $(this).closest('.megaMenuDropdown').find('.icon-arrow-down').toggleClass('rotate');
        $(this).closest('.megaMenuDropdown').find('.megaMenu_title ').toggleClass('navBlue');
        const mediaQueryMob = matchMedia('(max-width: 1118px)');


        $('.content').each(function () {
            if (!$(this).hasClass('nohide')) {
                $(this).slideUp();
                $(this).closest('.megaMenuDropdown').find('.icon-arrow-down').removeClass('rotate');
                $(this).closest('.megaMenuDropdown').find('.megaMenu_title ').removeClass('navBlue');

            } else { }
        });
    }
    checkInnerDropDHeight($(this));

});
$('.tag_Menu_Button').on('click', function () {
    var $dropdownSubContainer = $(this).closest('.dropdown__container').find('.dropdownSub__container');
    setTimeout(function () {
        // Check if the 'transparent_scrollbar' class is present
        if (!$dropdownSubContainer.hasClass('transparent_scrollbar') && !$dropdownSubContainer.hasClass('show-scrollbar')) {
            // Only add 'show-scrollbar' class if 'transparent_scrollbar' is not present
            $dropdownSubContainer.addClass('show-scrollbar');
        } else {
            $dropdownSubContainer.removeClass('show-scrollbar');
        }
    }, 500);
    checkInnerDropDHeight($(this))
})

// Function to check height and apply transparency in scrolbar inner menu
function checkInnerDropDHeight(clickedElement) {
    // Attach click event listener to all dropdown buttons
    $('.transparent_scrollbar').removeClass('transparent_scrollbar');
    setTimeout(function () {
        var dropdownSubContainerMenu = clickedElement.closest('.dropdown__container').find('.dropdownSub__container');
        if (dropdownSubContainerMenu.length) {
            var containermegaMenu = dropdownSubContainerMenu[0];
            if (containermegaMenu) {
                var isScrollablemegaMenu = containermegaMenu.scrollHeight > containermegaMenu.clientHeight;

                if (isScrollablemegaMenu) {
                    dropdownSubContainerMenu.removeClass('transparent_scrollbar'); // Remove transparent class

                    var $dropdownSubContainer = $(clickedElement).closest('.dropdown__container').find('.dropdownSub__container');
                    setTimeout(function () {
                        // Check if the 'transparent_scrollbar' class is present
                        if (!$dropdownSubContainer.hasClass('transparent_scrollbar') && !$dropdownSubContainer.hasClass('show-scrollbar')) {
                            $dropdownSubContainer.addClass('show-scrollbar');
                        }
                    }, 200);
                } else {
                    dropdownSubContainerMenu.addClass('transparent_scrollbar'); // Add transparent class
                    setTimeout(function () {
                        $dropdownSubContainer.removeClass('show-scrollbar');
                    }, 200);
                }
            }
        }
    }, 400);
}

function checkDropdownHeight(dropdownButton) {
    $('.transparent_scrollbar').removeClass('transparent_scrollbar');
    var dropdownSubContainer = $(dropdownButton).siblings('.dropdown__container').find('.dropdownSub__container');
    // Check if dropdownSubContainer exists
    if (dropdownSubContainer.length) {
        var container = dropdownSubContainer[0];
        if (container) {
            var isScrollable = container.scrollHeight > container.clientHeight;
            if (isScrollable) {
                dropdownSubContainer.removeClass('transparent_scrollbar');
                var $dropdownSubContainer = $(dropdownButton).siblings('.dropdown__container').find('.dropdownSub__container');
                setTimeout(function () {
                    // Check if the 'transparent_scrollbar' class is present
                    if (!$dropdownSubContainer.hasClass('transparent_scrollbar') && !$dropdownSubContainer.hasClass('show-scrollbar')) {
                        $dropdownSubContainer.addClass('show-scrollbar');
                    }
                }, 200);
            } else {
                dropdownSubContainer.addClass('transparent_scrollbar'); // Add transparent class
                var $dropdownSubContainer = $(dropdownButton).siblings('.dropdown__container').find('.dropdownSub__container');
                setTimeout(function () {
                    $dropdownSubContainer.removeClass('show-scrollbar');
                }, 200);
            }
        }
    }
}


// 3. Create a function to display the dropdown
const toggleItem = (item) => {

    const mediaQueryMob = matchMedia('(max-width: 1118px)');
    if (item.classList.contains('show-dropdown')) {
        item.classList.remove('show-dropdown');
        if (!item.classList.contains('ProfessionalMenu')) {
            $('.content').slideUp();
            $('.icon-arrow-down').removeClass('rotate');
            $('.megaMenu_title ').removeClass('navBlue');
        }
    } else {

        if (mediaQueryMob) {
            item.classList.toggle('show-dropdown', {
                direction: "right"
            }, 1000);
        }
    }
}
// Select the element
const headerElement = document.querySelector('.mobile_Header_top');

// Function to check screen size and hide element
function checkScreenSize() {
    if (window.matchMedia('(max-width: 1118px)').matches) {
        // If screen is between 768px and 1118px, hide the element
        headerElement.style.display = 'none';
    } else {
        // Otherwise, make sure the element is visible
        headerElement.style.display = '';
    }
}

// Run on initial load
checkScreenSize();

// Add event listener to re-check on resize
window.addEventListener('resize', checkScreenSize);

document.addEventListener('DOMContentLoaded', function () {
    // Select the elements
    const navTogglefor = document.querySelector('.nav__data #nav-toggle');
    const mobileHeaderTop = document.querySelector('.mobile_Header_top');
    const headerTop = document.querySelector('.header-top');
    let navToggleCross = document.querySelector('.nav__data #nav-toggle .nav__toggle-close');

    // Check if the navToggle element exists
    if (navTogglefor) {
        // Add a click event listener on the #nav__toggle element
        navTogglefor.addEventListener('click', function () {
            // Remove d-none from .mobile_Header_top
            mobileHeaderTop.style.display = 'block';


            // Add d-none to .header-top
            headerTop.classList.add('d-none');
            let navToggleCross = document.querySelector('.nav__data #nav-toggle .nav__toggle-close');

            if (!navTogglefor.classList.contains('show-icon')) {

                setTimeout(function () {
                    // Remove d-none from .mobile_Header_top
                    mobileHeaderTop.style.display = 'none';

                    // Add d-none to .header-top
                    headerTop.classList.remove('d-none');
                }, 500); // 2000ms = 2 seconds
            }

        });
    } else {
        console.error('#nav__toggle not found!');
    }


});

var width = $(window).width();
if (width <= 1117) {
    $(".mobile_Header_top .country-selector .dropdown__container").css("max-height", $(window).height() - 40);
}
$(".footer-box3 > .footer-contact-icons > h3").attr("tabindex", "0");
$(".footer-box1 > .vspace-md").next().attr("tabindex", "0");
$(".footer-box1 > img").attr("tabindex", 0);
$(".Promobox--SingleCard").not(".promofullWidthImage").parent(".row").addClass("gapincard");
$(".article-infographic-wrapper").not(".promofullWidthImage").parent(".row").addClass("gapinInfocard");
var chklang = $(".header-top .language-selector").text().replace(/ /g, '').trim();
if (chklang == null || chklang == "") {
    $(".language-selector").css("padding-left", "0px");
}
//remove push content functionality from market selector
$('.market-selector, .nav__link').on('click keypress', function (event) {
    // $('.main_content').css('margin-top', 'unset');
    var lang = $(".header-top .language-selector").text().replace(/ /g, '');
    lang.replace(/[\n\r]+/g, '');
    var region = $(".header-top .regionCls").text();
    var combined = region + lang;
    var combined_str_len = combined.replace(/[\n\r]+/g, '').length / 2;
    $(".header-top .show-dropdown .country-selector .dd-arrow-icon").css("margin-left", 18 + combined_str_len * 4);
})

function tog(v) {
    return v ? "addClass" : "removeClass";
}
$(document).on("input", ".clearable", function () {
    $(this)[tog(this.value)]("x");
}).on("mousemove", ".x", function (e) {
    $(this)[tog(this.offsetWidth - 18 < e.clientX - this.getBoundingClientRect().left)]("onX");
}).on("touchstart click", ".onX", function (ev) {
    ev.preventDefault();
    $(this).removeClass("x onX").val("").change();
});
const input = document.querySelector('.search-country-list')
const clearButton = document.querySelector(".clear-input-button")
var continent = $('.mobile_Header_top .country-selector a.dropdown__title');
var countryName = $('.mobile_Header_top .country-selector ul li a');
var $allListElements = countryName;
const handleInputChange = (e) => {
    if (e.target.value && !input.classList.contains("clear-input--touched")) {
        input.classList.add("clear-input--touched")
    } else if (!e.target.value && input.classList.contains("clear-input--touched")) {
        input.classList.remove("clear-input--touched");
        $allListElements.show();
        $('.mobile_Header_top .country-selector .country-list-data').css("display", "block");
        $('.mobile_Header_top .country-selector .column .no_results').remove();
        $('.mobile_Header_top .country-selector ul').css("display", "block");
        $('.mobile_Header_top .country-selector ul li a').css("display", "block");
        $('.mobile_Header_top .country-selector .column .no_results').remove();
    }
}
const handleButtonClick = (e) => {
    input.value = ''
    input.focus()
    input.classList.remove("clear-input--touched");
    $allListElements.show();
    $('.mobile_Header_top .country-selector .country-list-data').css("display", "block");
    $('.mobile_Header_top .country-selector a.dropdown__title').css("display", "block");
    $('.mobile_Header_top .country-selector ul').css("display", "block");
    $('.mobile_Header_top .country-selector ul li a').css("display", "block");
    $('.mobile_Header_top .country-selector .column .no_results').remove();
}
clearButton.addEventListener("click", handleButtonClick);
input.addEventListener("input", handleInputChange);
$(document).on('keyup', ".search-country-list", function (e) {
    $('.mobile_Header_top .country-selector .column ul.no_results').remove();
    $('.mobile_Header_top .country-selector .country-list-data').css("display", "block");
    $('.mobile_Header_top .country-selector a.dropdown__title').css("display", "block");
    $('.mobile_Header_top .country-selector .country-list-data > ul').css("display", "block");
    $('.mobile_Header_top .country-selector ul li a').css("display", "block");
    var filter = $(this).val();
    $(this)[tog(this.value)]("x");
    if (filter != null && filter.length > 0) {
        var $matchingRegionElements = null;
        var regionElements = [];
        $matchingRegionElements = continent.filter(function (i, li) {
            var listItemText = $(this).text().toLowerCase();
            searchText = filter.toLowerCase();
            if (listItemText.indexOf(searchText) > -1) {
                return listItemText;
            }
        });
        if ($matchingRegionElements.length > 0) {
            $allListElements.hide();
            continent.hide();
            $('.mobile_Header_top .country-selector ul').css("display", "none");
            $matchingRegionElements.show();
            $matchingRegionElements.filter(function (i, li) {
                $(this).next().css("display", "block");
                $(this).next().find("li >a").css("display", "block");
            });
        } else {
            var $matchingListElements = $allListElements.filter(function (i, li) {
                var listItemText = $(this).text().toLowerCase();
                searchText = filter.toLowerCase();
                if (listItemText.indexOf(searchText) > -1) {
                    return listItemText;
                }
            });
            if ($matchingListElements.length <= 0) {
                $('.mobile_Header_top .country-selector .column .no_results').remove();
                $('.mobile_Header_top .country-selector .country-list-data').css("display", "none");
                var labelVal = $('input[name=NoRecordsLabel]').val()
                $('.mobile_Header_top .country-selector .column').append("<ul class='dropdown__list no_results'><li>" + labelVal + "</li></ul>");
            } else {
                $('.mobile_Header_top .country-selector .country-list-data').css("display", "block");
                $('.mobile_Header_top .country-selector .column .no_results').remove();
            }
            $allListElements.hide();
            $matchingListElements.show();
            $matchingRegionElements = continent.filter(function (i, li) {
                var len = $(this).next().find("li >a:not([style*='display: none'])").length;
                if (len > 0) {
                    $(this).css("display", "block");
                    $(this).next().css("display", "block");
                } else {
                    $(this).css("display", "none");
                    $(this).next().css("display", "none");
                }
            });
        }
    } else {
        $allListElements.show();
        $('.mobile_Header_top .country-selector .country-list-data').css("display", "block");
        $('.mobile_Header_top .country-selector a.dropdown__title').css("display", "block");
        $('.mobile_Header_top .country-selector ul').css("display", "block");
        $('.mobile_Header_top .country-selector ul li a').css("display", "block");
        $('.mobile_Header_top .country-selector .column .no_results').remove();
    }
});
/*=============== remove DROPDOWN STYLES ===============*/
const mediaQuery = matchMedia('(min-width: 1118px)'),
    dropdownContainer = document.querySelectorAll('.dropdown__container')
// Function to remove dropdown styles in mobile mode when browser resizes
const removeStyle = () => {
    // $('.main_content').css('margin-top', "0px");
    /* $('.stickyDiv').addClass('sticky');*/
    //reset thirdleveldropdown
    $('.content').slideUp();
    $('.icon-arrow-down').removeClass('rotate');
    $('.megaMenu_title ').removeClass('navBlue');

    // Validate if the media query reaches 1118px
    if (mediaQuery.matches) {
        // Remove the dropdown container height style
        dropdownContainer.forEach((e) => {
            e.removeAttribute('style')
        })
        // Remove the show-dropdown class from dropdown item
        dropdownItems.forEach((e) => {
            e.classList.remove('show-dropdown');

        })
    }
}
//close mobile nav
$('.close').on('click', function () {
    $('.content').slideUp();
    $('.content').closest('.megaMenuDropdown').find('.icon-arrow-down').removeClass('rotate');
    $('.content').closest('.megaMenuDropdown').find('.megaMenu_title ').removeClass('navBlue');
    $('.dropdown__item').removeClass('show-dropdown');
})
//sticky header onscroll
jQuery(function ($) {
    var currentHeaderScrollPos = window.scrollY;
    if (currentHeaderScrollPos == 0) {
        // $('.header').css('top','-60px');
        $('.sticky').css('position', 'unset');
        $('.sticky').css('z-index', '3');
        $('.sticky').parents('.nav').css('top', '0');
        $('.sticky').removeClass('menuScrollMargin')
        $('body').removeClass('stickyMenuOpen');
    }
});
//sticky header onscroll
addEventListener('resize', removeStyle)
var prevScrollpos = window.scrollY;
var functionScroll = function () {
    var currentHeaderScrollPos = window.scrollY;
    var stickyHeight = '-' + $('.sticky').outerHeight() + 'px';
    var navHeight = '-' + $('.header-top ul').outerHeight() + 'px';
    if (prevScrollpos > currentHeaderScrollPos) {
        $('.searchSticky').css('display', 'block');
        $('.sticky').css('top', '0');
        $('.sticky').parents('.nav').css('top', '0');
        $('.sticky').addClass('menuScrollMargin')
        $('body').addClass('stickyMenuOpen');
    } else {
        if (currentHeaderScrollPos <= 55) {
            $('.sticky').parents('.nav').css('top', navHeight);
            $('.sticky').css('position', '');
            $('.sticky').css('top', stickyHeight);
            $('.sticky').removeClass('menuScrollMargin');
            $('body').removeClass('stickyMenuOpen');
        } else {
            $('.sticky').css('position', '');
            $('.sticky').css('top', stickyHeight);
            $('.sticky').removeClass('menuScrollMargin')
            $('body').removeClass('stickyMenuOpen');
            //    $('.sticky').parents('.nav').css('top', navHeight);
            $('.searchSticky').css('display', 'block ');
        }
    }
    if (currentHeaderScrollPos == 0) {
        $('.searchSticky').css('display', 'none');
        $('.sticky').css('position', 'unset');
        $('.sticky').parents('.nav').css('top', '0');
        $('.sticky').removeClass('menuScrollMargin');
        $('body').removeClass('stickyMenuOpen');
    }
    prevScrollpos = currentHeaderScrollPos;
}
window.onscroll = function () {
    functionScroll();
}
jQuery(function ($) {
    functionScroll();
})
//outside click and escape key close menu
if (mediaQuery.matches) {
    $(document).on('mouseup', function (e) {
        if (!$(".dropdown__container, .header").is(e.target) && $(".header").is(e.target) && $(".dropdown__item, .header").has(e.target).length === 0 && $(" .header").has(e.target).length === 0) {
            removeStyle();
            updateActiveStateMenu();
        }

    });

    $('.overlayMegamenu').on('click keypress', function (event) {
        updateActiveStateMenu();
        // Handle click and keyboard "Enter" keypress events
        if (event.type === "click" || (event.type === "keypress" && event.key === "Enter")) {
            removeStyle();
        }
    });
    $('.dropdown__button').on('click keypress', debounce(function (event) {
        if (event.type === "click" || (event.type === "keypress" && event.key === "Enter")) {
            if (!$(this).parent('.dropdown__item').hasClass('show-dropdown')) {
                updateActiveStateMenu();
            }
        }
    }, 300)); // Adjust the delay time as needed
    // Handle focus on last navigation item
    $('.nav__list .dropdown__item:last-child a ').on('focusout', function (e) {
        if (!$(e.relatedTarget).closest('.nav-menu').length) {
            removeStyle();
            updateActiveStateMenu();
        }
    });
}
//remove megamenu escape
$(document).keydown(function (e) {
    if (e.key === "Escape") {
        removeStyle();
        overlayAddMenu();
    }
});
//active state underline desktop
let updateActiveStateMenu = function () {
    var url = window.location.href;
    var $megamenuAllHref = $('.megamenuNodropdown a, .innerMegamenuContent a, .breadcrumb a, .dropdown__item #TpwPageUrl');

    $megamenuAllHref.each(function () {
        var href = $(this).attr("href");
        var hrefHidden = $(this).val();
        if (url.indexOf(href) !== -1 || $(this).attr("href") == url || url.indexOf(hrefHidden) !== -1 && hrefHidden != "" || $(this).val() == url) {
            $(this).parents('.dropdown__container').siblings('.nav__link').addClass('active');
            $(this).parents('.dropdown__item').find('.nav__link').addClass('active');
            //   $(this).siblings('.nav__link').addClass('active');
        }
    });
};


//mobile filter bottom fixed 
$(window).on('resize', function () {
    updateActiveStateMenu();
    const mediaQueryMob = matchMedia('(max-width: 1118px)');
    if (mediaQueryMob.matches) {
        var currentMenuHeight = window.innerHeight;
        var Navheight = $('.nav').outerHeight();
        currentMenuHeight = currentMenuHeight - Navheight;
        $('.show-menu .nav__list').css('height', currentMenuHeight + 'px');
        var fixedBottomHeight = $('.select_interest_bottomFixed').outerHeight();
        var totalNavlistheight = $('.nav__list').outerHeight();
        var heightInnerScroll = totalNavlistheight - fixedBottomHeight;
        $('.dropdown__container').css('height', heightInnerScroll + 'px');
    }
})

jQuery(function ($) {
    if (mediaQuery.matches) {
        updateActiveStateMenu();
    }
})

//add overlay and overflow hidden 
const mediaQueryDesk = matchMedia('(min-width: 1118px)');
if (mediaQueryDesk.matches) {
    const navList = document.querySelector('.header');

    function overlayAddMenu() {
        const dropdownItems = Array.from(navList.querySelectorAll('.dropdown__item'))
            .filter(item => !item.classList.contains('CountrySelector'));
        let overlayVisible = false;
        dropdownItems.forEach(item => {
            const overlayMegamenu = item.querySelector('.overlayMegamenu');
            if (item.classList.contains('show-dropdown') && overlayMegamenu) {
                overlayVisible = true; // Check if the overlay is visible
            }
        });
        if (overlayVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }
    document.addEventListener('click', (event) => {
        if (!navList.contains(event.target)) {
            document.body.style.overflow = 'auto';
            const openDropdowns = navList.querySelectorAll('.show-dropdown');
            openDropdowns.forEach(dropdown => dropdown.classList.remove('show-dropdown'));
            overlayAddMenu();
        }
    });
    navList.addEventListener('click', (event) => {
        const clickedItem = event.target.closest('.dropdown__item');
        if (clickedItem) {
            setTimeout(overlayAddMenu, 200);
        }
    });

    overlayAddMenu();
    $(window).on('resize', function () {
        overlayAddMenu();
    })
    $(window).on('popstate', function () {
        removeStyle();
        overlayAddMenu();
    });
}